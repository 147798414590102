import { useEffect, useState } from 'react';

import { isBrowser } from 'utilities/utils';

const useMedia = (query, defaultState = false) => {
  const [state, setState] = useState(
    isBrowser ? () => window.matchMedia(query).matches : defaultState
  );

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };
    const isModern = 'addEventListener' in mql;
    if (isModern) {
      mql.addEventListener('change', onChange);
    } else {
      mql.addListener(onChange);
    }
    setState(mql.matches);

    return () => {
      mounted = false;
      if (isModern) {
        mql.removeEventListener('change', onChange);
      } else {
        mql.removeListener(onChange);
      }
    };
  }, [query]);

  return state;
};

export default useMedia;
