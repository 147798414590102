import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { IconContext } from 'react-icons';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoPinterest,
  IoCheckmark,
} from 'react-icons/io5';
import Select from 'react-select';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from 'react-share';
import SwiperCore, { Zoom } from 'swiper';
import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/zoom/zoom.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import CartIcon from 'components/CartIcon/CartIcon';
import Container from 'components/Container/Container';
import Layout from 'components/Layout';
import OffersCarousel from 'components/OffersCarousel/OffersCarousel';
import Price from 'components/Price/Price';
import QuantityButton from 'components/QuantityButton/QuantityButton';
import Seo from 'components/Seo';
import TitleBrand from 'components/TitleBrand/TitleBrand';

import useData from 'data/useData';

import useMedia from 'hooks/useMedia';

import dataLayerPush from 'utilities/dataLayer';
import fbqEvent from 'utilities/fbq';

import CartStore from 'store/cart';

import { linkUnderlined } from 'styles/components/links.module.scss';
import { productHeader } from 'styles/components/typography.module.scss';
import * as styles from 'styles/pages/product.module.scss';
import { imageContainer } from 'styles/pages/product.module.scss';

import 'styles/overrides/react-select.scss';
import 'styles/overrides/swiper-product.scss';
import 'styles/pages/product.scss';

SwiperCore.use([Zoom]);

const settings = {
  spaceBetween: 20,
  slidesPerView: 1,
  zoom: true,
  autoHeight: true,
  className: 'swiper-product-container',
};

const imgStyle = { height: 'auto' };
const sizeStyle = { size: '24px' };

const hasSpecs = (product) => {
  return (
    product &&
    (product.itemDiameter ||
      product.itemLength ||
      product.itemThickness ||
      product.itemExpansion ||
      (product.material && product.material.title))
  );
};

export default function ProductPage({
  data: { strapiProduct: product, site, strapiCategory: category },
}) {
  const { data: relatedProducts } = useData(
    `/products/related?category=${category.strapiId}&sku=${product.sku}`
  );
  const isNarrow = useMedia('(max-width: 1024px)');
  const [quantity, setQuantity] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const size = data.size ? { size: data.size.value } : {};
    addToCart(size);
  };

  const options = product.variants.map(({ size }) => ({
    value: size,
    label: size,
  }));
  const heroImage =
    category && category.localFile && category.localFile[0]
      ? getImage(category.localFile[0])
      : category && category.localFile && category.localFile[1]
      ? getImage(category.localFile[1])
      : null;

  const addToCart = (size = {}) => {
    CartStore.addCartItem({ ...product, ...size }, quantity);
    setShowSuccess(true);
    try {
      fbqEvent('track', 'AddToCart', {
        value: product.finalPrice / 100,
        currency: 'EUR',
        content_type: 'product',
        content_ids: product.sku,
      });
      dataLayerPush({ ecommerce: null });
      dataLayerPush({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [
              {
                name: product.title,
                id: product.sku,
                price: product.finalPrice / 100,
                category: category.title,
                quantity: quantity,
              },
            ],
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      fbqEvent('track', 'ViewContent', {
        value: product.finalPrice / 100,
        currency: 'EUR',
        content_type: 'product',
        content_ids: product.sku,
      });
      dataLayerPush({ ecommerce: null });
      dataLayerPush({
        ecommerce: {
          detail: {
            products: [
              {
                name: product.title,
                id: product.sku,
                price: product.finalPrice / 100,
                category: category.title,
              },
            ],
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let mounted = true;
    if (showSuccess === true) {
      setTimeout(() => {
        mounted && setShowSuccess(false);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [showSuccess]);

  return (
    <Layout fullWidth={true}>
      <Seo
        title={`${product.title} | ${category.title}${
          category.strapiParent && ' | ' + category.strapiParent.title
        }`}
        description={
          product.description ||
          `Δείτε ${product.title} στην κατηγορία ${category.title}. Γυναικεία κοσμήματα, ρολόγια, αξεσουάρ υψηλής ποιότητας σε μοναδικές τιμές από το ηλεκτρονικό κατάστημα Nicolas Jewelry.`
        }
        meta={product.images.map((image) => {
          return { property: 'og:image', content: image.url };
        })}
      />
      <div className={styles.banner}>
        {heroImage ? (
          <GatsbyImage image={heroImage} className={styles.hero} alt="" />
        ) : (
          <StaticImage
            layout="fullWidth"
            quality={100}
            alt=""
            src={'../images/category.jpg'}
            formats={['auto', 'webp', 'avif']}
            className={styles.hero}
          />
        )}
        <div
          className={`${styles.bannerContent}${
            heroImage ? ' ' + styles.overlay : ''
          }`}
        >
          <div className={styles.breadcrumbs}>
            <Link className={`${linkUnderlined} ${styles.link}`} to="/">
              ΑΡΧΙΚΗ
            </Link>
            <span className={styles.dot}>&middot;</span>
            {category.strapiParent && category.strapiParent.slug && (
              <>
                <Link
                  className={`${linkUnderlined} ${styles.link}`}
                  to={`/${category.strapiParent.slug}`}
                >
                  {category.strapiParent.title}
                </Link>
                <span className={styles.dot}>&middot;</span>
              </>
            )}
            <Link
              className={`${linkUnderlined} ${styles.link}`}
              to={`${
                (category.strapiParent &&
                  category.strapiParent.slug &&
                  '/' + category.strapiParent.slug) ||
                ''
              }/${category.slug}`}
            >
              {category.title}
            </Link>
            <span className={styles.dot}>&middot;</span>
            <Link
              className={`${linkUnderlined} ${styles.link} ${styles.active}`}
              to={product.relativePath}
            >
              {product.title}
            </Link>
          </div>
        </div>
      </div>
      <Container containerClass={styles.mainContainer}>
        <div className={styles.container}>
          {product && product.images && product.images.length > 0 && (
            <div className={styles.imgContainer}>
              {isNarrow ? (
                <Swiper {...settings}>
                  {product.localFile.map((image, i) => (
                    <div key={i} className="swiper-zoom-container">
                      <SwiperSlide key={i}>
                        <GatsbyImage
                          className={imageContainer}
                          imgStyle={imgStyle}
                          image={getImage(image)}
                          alt={`${product.title} ' - ' ${category.title}`}
                        />
                      </SwiperSlide>
                    </div>
                  ))}
                </Swiper>
              ) : (
                <>
                  {product.localFile.map((image, i) => (
                    <GatsbyImage
                      key={i}
                      className={imageContainer}
                      imgStyle={imgStyle}
                      image={getImage(image)}
                      alt={`${product.title} ' - ' ${category.title}`}
                    />
                  ))}
                </>
              )}
            </div>
          )}
          <div className={styles.details}>
            <h1 className={`${productHeader}`}>{product.title} </h1>
            {product.sticker ? (
              <span className={styles.sticker}>{product.sticker}</span>
            ) : null}
            <h3 className={styles.skuContainer}>
              <span className={styles.label}>ΚΩΔΙΚΟΣ ΠΡΟΪΟΝΤΟΣ:</span>{' '}
              <span className={styles.spec}>{product.sku}</span>
            </h3>
            <h3 className={styles.available}>
              <IconContext.Provider value={{ size: '1.25rem' }}>
                <IoCheckmark style={{ marginRight: '0.25rem' }} />
              </IconContext.Provider>
              Άμεσα διαθέσιμο
            </h3>
            {hasSpecs(product) ? (
              <div className={styles.specsSection}>
                <p>ΧΑΡΑΚΤΗΡΙΣΤΙΚΑ</p>
                <div className={styles.specsContainer}>
                  {product.material && product.material.title ? (
                    <div>
                      <span className={styles.label}>Υλικό:</span>{' '}
                      <span className={styles.spec}>
                        {product.material.title}
                      </span>
                    </div>
                  ) : null}
                  {product.itemLength ? (
                    <div>
                      <span className={styles.label}>Μήκος:</span>{' '}
                      <span className={styles.spec}>
                        {product.itemLength}
                        {product.itemLengthUnit}
                      </span>
                    </div>
                  ) : null}
                  {product.itemThickness ? (
                    <div>
                      <span className={styles.label}>Πάχος:</span>{' '}
                      <span className={styles.spec}>
                        {product.itemThickness}
                        {product.itemThicknessUnit}
                      </span>
                    </div>
                  ) : null}
                  {product.itemDiameter ? (
                    <div>
                      <span className={styles.label}>Διάμετρος:</span>{' '}
                      <span className={styles.spec}>
                        {product.itemDiameter}
                        {product.itemDiameterUnit}
                      </span>
                    </div>
                  ) : null}
                  {product.itemExpansion ? (
                    <div>
                      <span className={styles.label}>Επέκταση:</span>{' '}
                      <span className={styles.spec}>
                        {product.itemExpansion}
                        {product.itemExpansionUnit}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className={styles.priceContainer}>
              <Price
                beginPrice={product.beginPrice}
                finalPrice={product.finalPrice}
                fpClass={styles.fpClass}
                bpClass={styles.bpClass}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.addToCartContainer}>
                <QuantityButton
                  containerClass={styles.quantityContainer}
                  quantity={quantity}
                  add={() => quantity < 9 && setQuantity(quantity + 1)}
                  subtract={() => quantity > 1 && setQuantity(quantity - 1)}
                />
                {product.variants && product.variants.length > 0 ? (
                  <Controller
                    name="size"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={styles.select}
                        classNamePrefix="react-select"
                        label="Επιλέξτε μέγεθος"
                        placeholder="Επιλέξτε μέγεθος"
                        options={options}
                        isSearchable={false}
                      />
                    )}
                  />
                ) : null}
              </div>
              <PrimaryButton buttonClass={styles.addToCartBtn} type="submit">
                <CartIcon />
                ΠΡΟΣΘΗΚΗ ΣΤΟ ΚΑΛΑΘΙ
              </PrimaryButton>
              {errors.size && (
                <p className={styles.errorMsg}>Πρέπει να επιλέξεις μέγεθος.</p>
              )}
              <p
                className={`${styles.successMsg}${
                  showSuccess ? ' ' + styles.active : ''
                }`}
              >
                Το προϊόν προστέθηκε επιτυχώς στο καλάθι!
              </p>
            </form>
            <div className={styles.socialContainer}>
              <p className={styles.socialText}>Μοιραστείτε το</p>
              <FacebookShareButton
                className={styles.socialBtn}
                url={`${site.siteMetadata.url}${product.relativePath}`}
              >
                <IconContext.Provider value={sizeStyle}>
                  <IoLogoFacebook />
                </IconContext.Provider>
              </FacebookShareButton>
              <TwitterShareButton
                className={styles.socialBtn}
                url={`${site.siteMetadata.url}${product.relativePath}`}
              >
                <IconContext.Provider value={sizeStyle}>
                  <IoLogoTwitter />
                </IconContext.Provider>
              </TwitterShareButton>
              <PinterestShareButton
                className={styles.socialBtn}
                url={`${site.siteMetadata.url}${product.relativePath}`}
                media={product.images[0].url}
              >
                <IconContext.Provider value={sizeStyle}>
                  <IoLogoPinterest />
                </IconContext.Provider>
              </PinterestShareButton>
            </div>
            {product.description && (
              <div className={styles.descriptionSection}>
                <p className={styles.descriptionTitle}>ΠΕΡΙΓΡΑΦΗ</p>
                <p className={styles.description}>{product.description}</p>
              </div>
            )}
          </div>
        </div>
      </Container>
      {relatedProducts && relatedProducts.length > 0 && (
        <OffersCarousel
          subtitle="Παρόμοια με το προϊόν που είδατε"
          title={<TitleBrand text="ΣΧΕΤΙΚΑ ΠΡΟΪΟΝΤΑ" />}
          products={relatedProducts}
          containerClass={styles.relatedContainer}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query SingleProduct($id: String!, $categoryId: String!) {
    site {
      siteMetadata {
        url
      }
    }
    strapiProduct(strapiId: { eq: $id }) {
      id: strapiId
      beginPrice
      description
      finalPrice
      itemLength
      itemLengthUnit
      itemDiameter
      itemDiameterUnit
      itemThickness
      itemThicknessUnit
      itemExpansion
      itemExpansionUnit
      enabled
      kind {
        title
      }
      material {
        title
      }
      offerType
      relativePath
      sku
      slug
      sticker
      title
      variants {
        quantity
        size
      }
      mainCategory {
        slug
        title
      }
      subCategory {
        slug
        title
      }
      images {
        hash
        url
      }
      localFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100)
        }
      }
    }
    strapiCategory(id: { eq: $categoryId }) {
      strapiId
      title
      strapiParent {
        title
        slug
      }
      slug
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  }
`;
